import React, { useState, useEffect } from 'react';
import Container from "../../components/Container";
import Wrapper from "../../components/Wrapper";
import {ReactComponent as Logo} from "../../assets/EM_logo.svg"
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import {ErrorMessage} from './index.style';
import * as api from '../../utils/api';
import { useLanguage } from '../../contexts/LanguageContext';

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { translate, currentLanguage, setCurrentLanguage } = useLanguage();

    const handleSubmit = (e) => {
        setError('');
        
        api.login(username, password).then(data => {
            if (data.access_token) {
                localStorage.setItem('username', username);
                window.location.href = '/';
            } 
            else {
                setError('Invalid username or password');
            }
        })
        .catch(err => {
            setError('Invalid username or password');
        });
    };

    useEffect(() => {
        const handleEnterKeyPress = (event) => {
            if ((event.code === 'Enter' || event.keyCode === 13)) {
                event.preventDefault();
                handleSubmit();
            }
        }
        window.addEventListener('keydown', handleEnterKeyPress);
        return () => {
            window.removeEventListener('keydown', handleEnterKeyPress);
        }
    }, [handleSubmit]);

    // dlear error when the user starts typing
    useEffect(() => {
        setError('');
    }, [username, password]);

    return (
        <Container>
            <Wrapper style={{
                display: "flex",
                flexDirection: "column",
                gap: 15,
                justifyContent: "center",
                alignItems: "center",
                height: '100%',
            }}>
                <Logo width="auto" height={36} style={{ display: 'block', width: 'auto', marginBottom: 30 }} />
                <CustomInput
                    label="Username"
                    placeholder="Username"
                    type="text"
                    style={{
                        width: 180,
                        height: 30,
                    }}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <CustomInput
                    label="Password"
                    placeholder="Password"
                    type="password"
                    style={{
                        width: 180,
                        height: 30,
                    }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <CustomButton
                    text={translate("signIn")}
                    onClick={handleSubmit}
                    style={{
                        width: 200,
                        height: 30,
                    }}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
            </Wrapper>
        </Container>
    );
}