import styled from 'styled-components';
import colors from '../../configs/colors';
import constants from '../../configs/constants';
import fontSizes from '../../configs/fontSizes';

// Width of the tasks list (left side of the container), in percentages of the container width
const LEFT_SIDE_PCT_WIDTH = 60;

const SIDE_CONTENT_PADDING_HORIZONTAL = 25;
const SIDE_CONTENT_PADDING_BOTTOM = 30;

const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 75%;
    margin-top: 135px;
    background-color: transparent;
    position: relative;
`;

const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    margin-right: 20px;
    background-color: white;
    height: 100%;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.1);
    border-radius: 8px;
    overflow: hidden;
`;

const RightSide = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;

    box-shadow: 0px 1px 3px rgba(0,0,0,0.1);
    border-radius: 8px;
    overflow: hidden;
    
`;


const SideHeadline = styled.div`
  display: flex;
  justify-content: space-between;  // This will push the DropZone to the right
  align-items: center;
  min-height: 60px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  font-size: ${fontSizes.bigger};
  font-weight: bold;
  padding-left: ${SIDE_CONTENT_PADDING_HORIZONTAL}px;
  padding-right: ${SIDE_CONTENT_PADDING_HORIZONTAL}px; // Add right padding for spacing
`;


const SideContentWrapper = styled.div`
    width: calc(100% - ${SIDE_CONTENT_PADDING_HORIZONTAL*2}px);
    height: calc(100% - ${SIDE_CONTENT_PADDING_BOTTOM}px - 60px);
    padding-left: ${SIDE_CONTENT_PADDING_HORIZONTAL}px;
    padding-right: ${SIDE_CONTENT_PADDING_HORIZONTAL}px;
    padding-bottom: ${SIDE_CONTENT_PADDING_BOTTOM}px;
    display: flex;
    flex-direction: column;
`;

const ProcessedCommentContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100% - 30px);
    padding: 15px 20px;
    border-radius: ${constants.borderRadius}px;
    border: 1px solid ${colors.border};
    overflow-y: hidden;
`;

const ProcessedCommentWrapper = styled.div`
    flex-grow: 1;
    font-size: ${fontSizes.regular}px;
    line-height: ${fontSizes.lineHeight.regular}px;
    flexWrap: wrap;
    overflow-y: scroll;
`;

const SuggestionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
`;

const SuggestionWindow = styled.div`
  position: relative;
  padding: 15px 20px 10px 10px; 
  border: 1px solid ${colors.border};
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
`;

const SuggestionText = styled.div`
    margin-bottom: 10px;
    font-size: ${fontSizes.regular}px;
    color: black;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 8px;
  top: 8px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: ${fontSizes.regular}px;
  color: black;
  padding: 5px;
  line-height: 1;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const RepairButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 94px;
    width: 100%;
    z-index: 1; 
`;

const CopyPopup = styled.div`
    position: absolute;
    top: 50%;  
    left: calc(100% + 10px);  
    transform: translateY(-50%);  
    background-color: white;
    color: black;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 0.9em;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
    white-space: nowrap;  
    animation: fade-in-out 3.1s ease-in-out;

    @keyframes fade-in-out {
        0% {
            opacity: 0;
            transform: translateY(-50%) translateX(10px); 
        }
        10% {
            opacity: 1;
            transform: translateY(-50%) translateX(0);
        }
        90% {
            opacity: 1;
            transform: translateY(-50%) translateX(0);
        }
        100% {
            opacity: 0;
            transform: translateY(-50%) translateX(10px);  
        }
    }
`;

export { MainContainer, LeftSide, RightSide, SuggestionsContainer, RepairButtonContainer, SuggestionWindow, CopyPopup, CloseButton, SuggestionText, ProcessedCommentContainer, SideHeadline, SideContentWrapper, ProcessedCommentWrapper }