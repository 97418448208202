import React from 'react';
import { UserGuideOverlay, UserGuideContent, Title, ButtonWrapper, KeyVisual, Instruction } from './index.style';

const UserGuidePopup = ({ onClose }) => {
  return (
    <UserGuideOverlay>
      <UserGuideContent>
        <ButtonWrapper>
        </ButtonWrapper>   
        <Instruction>
          <KeyVisual>Space</KeyVisual> = Audio opnemen
        </Instruction>
        <Instruction>
          <KeyVisual>P</KeyVisual> = Proces opmerkingen
        </Instruction>
        <Instruction>
          <KeyVisual>C</KeyVisual> = Copy
        </Instruction>
      </UserGuideContent>
    </UserGuideOverlay>
  );
};

export default UserGuidePopup;
