import React, { useRef, useEffect } from 'react';
import { NavbarDiv, NavbarInnerDiv, NavbarMenuContainer, NavbarMenu, NavbarMenuItem, NavbarMenuItemNumber } from "./index.style";
import { ReactComponent as Logo } from "../../assets/EM_logo.svg";
import { Link } from "react-router-dom";
import fontSizes from "../../configs/fontSizes";

function Navbar(props) {
    const menuRef = useRef(null);

    useEffect(() => {
        scrollToActiveItem();
    }, [props.selectedStep]);

    const scrollToActiveItem = () => {
        const activeItem = menuRef.current?.querySelector('[data-active="true"]');
        if (activeItem) {
            activeItem.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        }
    };
    return (
        <NavbarDiv>
            <NavbarInnerDiv>
                <Logo width="auto" height={36} style={{ display: 'block', width: 'auto' }} />
                <NavbarMenuContainer>
                    <NavbarMenu ref={menuRef}>
                        {props.steps.map((item, ix) => (
                            <NavbarMenuItem
                                key={item}
                                active={item === props.selectedStep}
                                onClick={() => props.onStepChange(item)}
                                data-active={item === props.selectedStep}
                                async-active={props.asyncList?.includes(item)}
                            >
                                <NavbarMenuItemNumber>{ix + 1}</NavbarMenuItemNumber>{item}
                            </NavbarMenuItem>
                        ))}
                    </NavbarMenu>
                </NavbarMenuContainer>
                <div>
                    <Link style={{
                            color: 'black',
                            textDecoration: 'none',
                            opacity: 0.2,
                            fontSize: fontSizes.regular,
                    }} onClick={props.onSignOut}
                    >Sign out</Link>
                </div>
            </NavbarInnerDiv>
        </NavbarDiv>
    );
}

export default Navbar;