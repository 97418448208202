import React, { createContext, useContext, useState } from 'react';

const translations = {
    en: {
        "yourNotes": "Your notes",
        "sapInput": "SAP Input",
        "guidanceList": "Guidance List",
        "userGuide": "User Guide",
        "startNewRepair": "Start New Repair",
        "save": "Save",
        "next": "Next",
        "previous": "Previous",
        "copy": "Copy",
        "copied": "Copied!",
        "continue": "Continue",

        "newNote": "New Note",
        "record": "Record notes",
        "processComments": "Process comments",
        "confirmAnswers": "Confirm answers",
        "edit": "Edit",
        "delete": "Delete",

        "enterNumber": "Enter number",
        "selectLanguage": "Select Language",

        "recordNote": "Record Note",
        "transcribing": "Transcribing...",
        "recordAnswer": "Record answer",
        "uploadComponent": "Upload Component",
        "signIn":"Sign in",
        "enterOrderNumber":"Enter Order Number",
        "pleaseEnter":"Please enter an order number to continue",

        "dutch": "Dutch",
        "english": "English",
    },
    nl: {
        "yourNotes": "Jouw notities",
        "sapInput": "SAP Invoer",
        "guidanceList": "Tips",
        "userGuide": "Gebruikersgids",
        "startNewRepair": "Start Nieuwe Reparatie",
        "save": "Opslaan",
        "next": "Volgende",
        "previous": "Vorige",
        "copy": "Kopiëren",
        "copied": "Gekopieerd!",

        "newNote": "Nieuwe Notitie",
        "record": "Notities opnemen",
        "processComments": "Verwerk opmerkingen",
        "confirmAnswers": "Bevestig antwoorden",
        "edit": "Bewerken",
        "delete":"Verwijderen",

        "enterNumber": "Voer nummer in",
        "selectLanguage": "Selecteer Taal",

        "uploadComponent": "Bestand uploaden",
        "recordNote": "Notitie opnemen",
        "transcribing": "Transcriberen...",
        "recordAnswer": "Antwoord opnemen",
        "signIn":"Inloggen",
        "continue": "Ga door",
        "enterOrderNumber":"Voer ordernummer in",
        "pleaseEnter":"Voer alstublieft een ordernummer in om door te gaan",

        "dutch": "Nederlands",
        "english": "Engels",
    }}

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [currentLanguage, setCurrentLanguage] = useState('nl');

    const translate = (key) => {
        return translations[currentLanguage][key] || key;
    };

    return (
        <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage, translate }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};