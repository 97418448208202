import networking from "../configs/networking"


async function getComponentData() {
    const URL = networking.apiAddress + 'component_data';
    const OPTIONS = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
    };
    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }
    return response.json();
} export { getComponentData }

async function uploadCSV(file) {
    console.log("now in uploadCSV api")
    const URL = networking.apiAddress + 'upload_csv';

    const formData = new FormData();
    formData.append('file', file);

    const OPTIONS = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: formData
    };
    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }
    return response;
}

async function processComments(comment, step, component, uuid,tempNumber) {
    const URL = networking.apiAddress + 'process_comment';

    const OPTIONS = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({
            'unprocessed_comments': comment,
            'step': step,
            'component': component,
            'tracking_uuid' : uuid,
            'tempNumber': tempNumber
        })
    };
    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
}

async function transcribeAudio(audio, language, component, step, uuid, tempNumber) {
    const URL = networking.apiAddress + 'transcribe';
    // Wrap the audio file in form data
    const audioBlob = await fetch(audio).then(r => r.blob());
    const formData = new FormData();
    const storedUsername = localStorage.getItem('username');

    formData.append('audio_file', audioBlob, 'recording.webm');
    formData.append('language', language);
    formData.append('component', component);
    formData.append('step', step);
    formData.append('tracking_uuid', uuid);
    formData.append('tempNumber', tempNumber);
    formData.append('user_name', storedUsername);

    const OPTIONS = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: formData,
    };

    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
}

async function processAnnotationAnswers(comment, step, component, uuid, tempNumber) {
    const URL = networking.apiAddress + 'review';
    const OPTIONS = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({
            'notes_with_feedback': comment,
            'step' : step,
            'component' : component,
            'tracking_uuid' : uuid,
            'tempNumber': tempNumber
        })
    };

    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
}


async function login(username, password) {
    const URL = networking.apiAddress + 'auth/login';
    const OPTIONS = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'username': username,
            'password': password
        })
    };

    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }

    const data = await response.json();

    // If the login is successful, store the token
    if (data.access_token) {
        localStorage.setItem('jwtToken', data.access_token);
    }

    return data;
}

async function validateToken() {
    const URL = networking.apiAddress + 'auth/validate';
    const response = await fetch(URL, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
    });

    if (response.ok) {
        return { valid: true };
    }

    if (response.status === 401) {
        const data = await response.json();
        if (data.expired) {
            console.log("Token expired.");
            return { valid: false, expired: true };
        }
    }

    return { valid: false, expired: false };
}

async function guidanceList(component, step) {
    const URL = networking.apiAddress + 'guidance_list';
    const OPTIONS = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({
            'component': component,
            'step': step,
        })
    };

    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
}
async function logout() {
    console.log("Authorization Header:", `Bearer ${localStorage.getItem('jwtToken')}`);
    const URL = networking.apiAddress + 'auth/logout';
    const OPTIONS = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
    };

    const response = await fetch(URL, OPTIONS);
    console.log("Response from logout API:", response.ok);
    if (!response.ok) {
        console.log("response not ok")
        throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json(); // Return the parsed JSON response
}


async function log_edited_admin(step, component, original_admin, edited_admin) {
    const URL = networking.apiAddress + 'edited_admin';
    const OPTIONS = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({
            'step': step,
            'component': component,
            'original_admin': original_admin,
            'edited_admin': edited_admin
        })
    };

    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
}

async function create_database_entry(tempNumber, component, step_data, update) {
    console.log("order_number: " + tempNumber);
    console.log("component: " + component);
    const URL = networking.apiAddress + 'create_database_entry';
    const OPTIONS = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({
            'tempNumber': tempNumber,
            'component': component,
            'step_data': step_data,
            'update': update
        })
    };

    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();

    // If it's an existing order and we're not updating, return the step data
    if (data.step_data && !update) {
        console.log("data.step_data: " + data.step_data);
        return {
            success: true,
            step_data: data.step_data
        };
    }

    return {
        success: true,
        message: data.message
    };
}

async function trackTime(uuid, component, step, name, order_nr, user_name, content) {
    const URL = networking.apiAddress + 'time_tracking';
    const OPTIONS = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({
            'uuid': uuid,
            'component': component,
            'step': step,
            'name': name,
            'content': content,
            'user_name': user_name,
            'order_nr': order_nr
        })
    };

    const response = await fetch(URL, OPTIONS);
    if (!response.ok) {
        throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
}

export { processComments, transcribeAudio, processAnnotationAnswers, login, validateToken, guidanceList, uploadCSV, log_edited_admin, trackTime, create_database_entry, logout }
