import React, {useEffect, useState, useCallback} from 'react';
import Wrapper from '../../components/Wrapper';
import './style.css';
import { LeftSide, MainContainer, ProcessedCommentContainer, ProcessedCommentWrapper, CopyPopup, RightSide, SideContentWrapper, RepairButtonContainer, SideHeadline, SuggestionsContainer, CloseButton, SuggestionText, SuggestionWindow} from './index.style';
import CustomButton from '../../components/CustomButton';
import CommentHighlight from '../../components/CommentHighlight';
import * as api from '../../utils/api';
import Container from '../../components/Container';
import Navbar from '../../components/Navbar';
import CustomInput from '../../components/CustomInput';
import CustomSelect from '../../components/CustomSelect';
import {useStepsContext} from '../../contexts/StepsContext';
import TranscriptEditor from '../../components/TranscriptEditor';
import TranscriptDisplay from '../../components/TranscriptDisplay';
import VoiceMemoDisplay from '../../components/VoiceMemoDisplay';
import AnnotationRecordButton from '../../components/AnnotationRecordButton';
import DropZone from '../../components/DropZone';
import GuidanceListPopup from '../../components/GuidanceList';
import UserGuide from '../../components/UserGuide';
import Spinner from '../../components/Spinner';
import OrderNumberModal from '../../components/OrderNumberPopup';
import { v4 as uuidv4 } from 'uuid';
import { useLanguage } from '../../contexts/LanguageContext';

function Main() {
    const { updateStepData, getStepData } = useStepsContext();
    
    const [selectedStep, setSelectedStep] = useState();
    const [selectedHighlight, setSelectedHighlight] = useState(null);
    const [hoveredHighlight, setHoveredHighlight] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);

    const [audioRecordings, setAudioRecordings] = useState([]);
    const [isTranscribing, setIsTranscribing] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const { transcripts = [], processedComments, parts, annotations } = getStepData(selectedStep);

    const [isCopied, setIsCopied] = useState(false);
    const [editingTranscript, setEditingTranscript] = useState(null);

    const [componentOptions, setComponentOptions] = useState([]);
    const [componentMenuOptions, setComponentMenuOptions] = useState([]);

    const [selectedComponent, setSelectedComponent] = useState('oven');
    const [selectedMenu, setSelectedMenu] = useState([]);
    const [recordingStartTime, setRecordingStartTime] = useState(null);


    const [selectedGuidance, setSelectedGuidance] = useState(null);
    const [showGuidancePopup, setShowGuidancePopup] = useState(false);

    const [showUserGuide, setShowUserGuide] = useState(false);

    const [lastProcessedTranscripts, setLastProcessedTranscripts] = useState({});

    const [isEditingOutput, setIsEditingOutput] = useState(false);
    const [editedParts, setEditedParts] = useState([]);
    const [showNumberInput, setShowNumberInput] = useState(false);
    const [orderNumber, setOrderNumber] = useState('');
    const [tempOrderNr, setTempOrderNr] = useState('');
    const [showOrderModal, setShowOrderModal] = useState(true);
    const { translate, currentLanguage, setCurrentLanguage } = useLanguage();
    const [asyncList, setAsyncList] = useState([]);
    const [copyPopup, setCopyPopup] = useState({ visible: false, message: "" });
   
    const TRANSCRIPT_LANGUAGES = [
        { value: 'nl', text: translate("dutch") },
        { value: 'en', text: translate("english") }
    ]

    useEffect(() => {
        if (!orderNumber) {
            setShowOrderModal(true);
        }
    }, []);

    useEffect(() => {
        setCopyPopup({ visible: false, message: "" });
    }, [selectedStep]);
    
    const createDatabaseEntry = async (orderNumber, component, stepData, update) => {
        try {
            const response = await api.create_database_entry(orderNumber, component, stepData, update);

            // Only process retrieved data when update is false
            if (!update && response.step_data) {
                console.log("Retrieved step data:", response.step_data);

                // For each step in the retrieved data
                Object.entries(response.step_data).forEach(([step, content]) => {
                    // Parse the text content
                    const parsedText = parseText(content);

                    // Update the step data in the same way as processComments does
                    updateStepData(step, {
                        processedComments: content,
                        parts: parsedText.parts,
                        annotations: parsedText.annotations
                    });

                    // Create a transcript entry for the step
                    const newTranscript = {
                        timestamp: new Date().toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                        }),
                        text: content,
                        carriedOver: true
                    };

                    // Get existing step data and update with the new transcript
                    const stepData = getStepData(step);
                    updateStepData(step, {
                        ...stepData,
                        transcripts: [...(stepData.transcripts || []), newTranscript]
                    });
                });
            }
        } catch (error) {
            console.error("Error in database entry:", error);
        }
    };


    useEffect(() => {
        const loadOrderData = async () => {
            if (orderNumber) {
                await createDatabaseEntry(orderNumber, "steam_oven", null, false);
            }
        };
        loadOrderData();
    }, [orderNumber]);

    const handleOrderNumSave = () => {
        const newUuid = uuidv4();
        setTrackingUuid(newUuid);

        const oldOrderNumber = orderNumber || "none"; // Use "none" if there's no previous order number
        timeTracking("save_ordernumber", `${oldOrderNumber}_${tempOrderNr}`);

        setOrderNumber(tempOrderNr);
        setShowOrderModal(false);
        setShowNumberInput(false);
        setTempOrderNr('');

        const selectedMenu = componentMenuOptions.find(item => item.component === selectedComponent);
        if (selectedMenu) {
            selectedMenu.steps.forEach(step => {
                updateStepData(step, {
                    transcripts: [],
                    processedComments: '',
                    parts: [],
                    annotations: []
                });
            });
        }
    };

    const handleLanguageChange = (e) => {
        setCurrentLanguage(e.target.value);
    };

    const handleNumberButtonClick = () => {
        setShowOrderModal(true);
        timeTracking("new_repair")
    };

    const [activeIndex, setActiveIndex] = useState(0);

    const [trackingUuid, setTrackingUuid] = useState(() => {
        // Generate new UUID on page load
        return uuidv4();
    });


    useEffect(() => {
        if (parts.length > 0) {
            setEditedParts(parts.map(part => part.text.trim())); // Sync editedParts with parts text
        }
    }, [parts]);

    useEffect(() => {
        setIsEditingOutput(false);
    }, [selectedStep]);

    const log_edited_admin = () => {
        console.log("now in log_edited_admin");
        console.log("editedParts", editedParts);
        console.log("parts", parts);

        timeTracking("editing_clicked");
    
        const isNotEqual = editedParts.length !== parts.length || 
            !editedParts.every((editedText, index) => editedText === parts[index].text);
    
        if (isNotEqual) {
            const cleanedOriginalAdmin = parts.map(part => part.text).join('\n');
            const cleanedEditedAdmin = editedParts.join('\n');

            timeTracking("new_editing_output", cleanedEditedAdmin)

            api.log_edited_admin(selectedStep, selectedComponent, cleanedOriginalAdmin, cleanedEditedAdmin);
        }
    };

    const handleTokenExpiration = () => {
        console.log("Token expired, logging out...");
        localStorage.removeItem('jwtToken');
        window.location.href = '/login';
    };

    useEffect(() => {
        const checkTokenValidity = async () => {
            try {
                const response = await api.validateToken();

                if (!response.valid) {
                    if (response.expired) {
                        handleTokenExpiration();
                    } else {
                        console.error("Token validation failed for an unknown reason.");
                        handleTokenExpiration();
                    }
                }

            } catch (error) {
                handleTokenExpiration();
            }
        };
        const tokenCheckInterval = setInterval(checkTokenValidity, 900000);

        return () => clearInterval(tokenCheckInterval);
    }, []);

    const goToNextStep = () => {
        timeTracking("next_step")
        const currentIndex = selectedMenu.indexOf(selectedStep);
        if (currentIndex < selectedMenu.length - 1) {
            setSelectedStep(selectedMenu[currentIndex + 1]);
        }
    };
    
    const goToPreviousStep = () => {
        timeTracking("previous_step")
        const currentIndex = selectedMenu.indexOf(selectedStep);
        if (currentIndex > 0) {
            setSelectedStep(selectedMenu[currentIndex - 1]);
        }
    };

    const timeTracking = (action_type, content = "") => {
        const storedUsername = localStorage.getItem('username');
        api.trackTime(trackingUuid, selectedComponent, selectedStep, action_type, orderNumber, storedUsername, content).catch(err => console.error("TrackTime API Error:", err));
    }

    const handleToggleEditOutput = () => {
        if (isEditingOutput) {
            // Split textarea content into separate lines and save them as parts
            const newParts = editedParts
                .join('\n') // Ensure consistent handling of line breaks
                .split(/\r?\n/) // Split content into lines using line breaks
                .filter(line => line.trim() !== '') // Remove empty lines
                .map((line, index) => ({
                    text: line.trim(), // Save each line as a separate part
                    index: index,      // Ensure the index is updated correctly
                }));
            updateStepData(selectedStep, { parts: newParts });
        } else {
            // Combine parts into a single string when entering edit mode
            setEditedParts(parts.map(part => part.text));
        }
        setIsEditingOutput(!isEditingOutput); 
    
        log_edited_admin();
    };
     
    //check if something changed in the transcript compared to the previous version
    const hasTranscriptChanged = () => {
        const lastTranscripts = lastProcessedTranscripts[selectedStep] || []; // Default to an empty array
        return JSON.stringify(transcripts) !== JSON.stringify(lastTranscripts);
    };


    useEffect(() => {
        if (hasTranscriptChanged()) {
            processComments();
            setLastProcessedTranscripts(prevState => ({
                ...prevState,
                [selectedStep]: transcripts
            }));
        }
    }, [transcripts, selectedStep]);

    const getTextToCopy = () => {
        if (!parts || parts.length === 0) return '';
        
        const filteredText = parts
          .filter(part => !part.isAnnotated)
          .map(part => part.text.trim())
          .join('\n')
          .replace(/\n+$/g, '');
          
        console.log("filtered text to copy: ", filteredText);
        return filteredText;
      };

    const handleCopy = useCallback(async () => {
        const copiedText = getTextToCopy();
        timeTracking("copied_text");
        if (copiedText.trim()) { 
            const lineCount = copiedText.split('\n').length; // removed filtering of empty lines
            await navigator.clipboard.writeText(copiedText);
            setIsCopied(true);
            
            setCopyPopup({ 
                visible: true, 
                message: `${lineCount} line(s) copied to SAP!`
            });    
        } else {
            await navigator.clipboard.writeText('');
            setIsCopied(true);
        }
        
        setTimeout(() => {
            setCopyPopup({ visible: false, message: ""});
            setIsCopied(false);
        }, 3000);
    }, [getTextToCopy]);


    useEffect(() => {
        const fetchComponentData = async () => {
          const data = await api.getComponentData(); // Call the actual data-fetching function

                const transformedOptions = transformComponentData(data.component_data);
                setComponentOptions(transformedOptions);

                const transformedMenuItems = transformComponentStepsData(data.component_data);
                console.log("transformedMenuItems", transformedMenuItems);
                setComponentMenuOptions(transformedMenuItems);
                console.log("componentMenuOptions in function 1", componentMenuOptions);

                // Set initial component and step only if we have valid data
                if (transformedOptions.length > 0 && transformedOptions[0].options.length > 0) {
                    const initialComponent = transformedOptions[0].options[0].value;
                    setSelectedComponent(initialComponent);

                    const initialMenu = transformedMenuItems.find(item => item.component === initialComponent);
                    if (initialMenu && initialMenu.steps.length > 0) {
                        setSelectedStep(initialMenu.steps[0]);
                    }
                }
            }

        fetchComponentData(); // Call the async function once
      }, []); // Empty dependency array to run only on initial render

      const transformComponentData = (componentData) => {
        const categories = {};

        componentData.component_name.forEach((name, index) => {
            const category = componentData.component_category[index];
            if (!categories[category]) {
                categories[category] = {
                    category,
                    options: []
                };
            }
                categories[category].options.push({ value: name, text: name });
        });
   
        return Object.values(categories);

    };
    
    useEffect(() => {
        // Find the menu items corresponding to the selected component
        const foundMenu = componentMenuOptions.find(item => item.component === selectedComponent);


        if (foundMenu) {
          setSelectedMenu(foundMenu.steps);
          // Set the first step of the selected component if no step is selected
            if (!selectedStep || !foundMenu.steps.includes(selectedStep)) {
                setSelectedStep(foundMenu.steps[0]);
            }
        }

      }, [selectedComponent, selectedStep, componentMenuOptions]);  // Add componentMenuOptions to dependency array


      useEffect(() => {

      }, [componentMenuOptions]);  // This will log the updated value of componentMenuOptions after it changes

    
    const transformComponentStepsData = (componentData) => {
        
        const menuItems = componentData.component_name.map((name, index) => {
            const steps = componentData.component_steps[index]
            .map(step => step.trim()) // Trim whitespace

            return {
                component: name,
                steps: steps
            };
        });
    
        return menuItems;
    };


    const handleComponentChange = (e) => {
        const newComponent = e.target.value;
        setSelectedComponent(newComponent);
        setTrackingUuid(uuidv4()); // Create new UUID for new component

        const selectedMenu = componentMenuOptions.find(item => item.component === newComponent);
        if (selectedMenu) {
            // Reset selected step to the first step of the selected component
            setSelectedStep(selectedMenu.steps[0]);

            // Clear data for all steps of the new component
            selectedMenu.steps.forEach(step => {
              updateStepData(step, {
                transcripts: [],
                processedComments: '',
                parts: [],
                annotations: []
              });
            });
          } else {
            // Handle the case when the component is not found (optional)
            console.error('Component not found:', newComponent);
          }
    };

    const handleAreaClick = (event) => {
        // create a new note if the user clicks directly on the container
        if (event.target === event.currentTarget) {
            handleNewNote();
        }
    };

    const handleEditTranscript = (index) => {
        setEditingTranscript({...transcripts[index]});

        timeTracking("edit_transcript")
    };


    // moved difference in saving edited notes and saving new notes differently up to the function
    const handleSaveTranscript = (editedTranscript) => {
        const existingIndex = transcripts.findIndex(t => t.timestamp === editedTranscript.timestamp);
        let updatedTranscripts;
    
        if (existingIndex !== -1) {
            // Edit existing transcript
            updatedTranscripts = transcripts.map((t, index) =>
                index === existingIndex ? editedTranscript : t
            );
        } else {
            // Add new one
            updatedTranscripts = [...transcripts, editedTranscript];
        }
    
        // Update step data with new/edited transcripts
        updateStepData(selectedStep, { transcripts: updatedTranscripts });
        // No need to call processComments here directly
        timeTracking("added_note_content", updatedTranscripts)
    };

    // empty the processed comments container when all transcripts are deleted
    useEffect(() => {
        if (transcripts.length == 0) {
            updateStepData(selectedStep, {
                processedComments: '',
                parts: [],
                annotations: []
            });
        }
    });

    
    const handleDeleteTranscript = (index) => {
        const updatedTranscripts = transcripts.filter((_, i) => i !== index);
        console.log("Updated Transcripts:", updatedTranscripts);
    
        if (updatedTranscripts.length === 0) {
            console.log("All transcripts deleted, processing comments");
            processComments();
        }
    
        updateStepData(selectedStep, { transcripts: updatedTranscripts });

        timeTracking("transcript_deleted")
    }
    
    
    const handleNewNote = () => {
        const newTranscript = {
            timestamp: new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', second:'2-digit'}),
            text: '',
            carriedOver: false
        };
        setEditingTranscript(newTranscript);

        timeTracking("add_note_clicked")
    };


    useEffect(() => {
        // Check token, redirect to login if doesn't exist
        console.log("Stored JWT Token:", localStorage.getItem('jwtToken'));
        if (!localStorage.getItem('jwtToken')) {
            window.location.href = '/login';
        }

        // Check if the user is logged in
        api.validateToken().then(r => {
            if (!r)
                window.location.href = '/login';
        }).catch(e => {});

         // Ask for microphone access
         if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                const mimeType = 'audio/webm;codecs=opus';
                const newMediaRecorder = new MediaRecorder(stream, {mimeType});
                setMediaRecorder(newMediaRecorder);

                newMediaRecorder.ondataavailable = e => {
                    const audioData = e.data;
                    const audioSrc = URL.createObjectURL(audioData);
                    const timestamp = new Date().toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', second: '2-digit'});
                    setAudioRecordings(prevRecordings => [...prevRecordings, { url: audioSrc, timestamp }]);
                };
            });
        }
    }, []);

    useEffect(() => {
        // transcibe most recent recording added to the array
        const latestRecording = audioRecordings[audioRecordings.length - 1];
        if (latestRecording) {
            console.log('New audio recording added:', latestRecording);
            transcribeAudio(latestRecording);
        }
    }, [audioRecordings]);

    const startRecording = () => {
        if (mediaRecorder) {
            if (!isRecording) {
                setIsRecording(true);
                setRecordingStartTime(Date.now());
                mediaRecorder.start();
                console.log(mediaRecorder.state);
                console.log("recorder started");
                timeTracking("start_recording")
            } else {
                setIsRecording(false);
                mediaRecorder.stop();
                console.log(mediaRecorder.state);
                console.log("recorder stopped");
                timeTracking("stop_recording")
            }
        }
    };

    useEffect(() => {
        setShowGuidancePopup(false);
    }, [selectedStep]);
    
    useEffect(() => {
        setIsRecording(false);
    }, [selectedStep]);
    
    useEffect(() => {
        // Function to handle the spacebar press
        const handleSpacebarPress = (event) => {
            if (!editingTranscript && 
                !['input', 'textarea'].includes(document.activeElement.tagName.toLowerCase())) {
            if (event.code === 'Space' || event.keyCode === 32) {
                event.preventDefault(); // Prevents default spacebar scrolling behavior
                console.log('Spacebar pressed');
                timeTracking("spacebar_pressed")
                startRecording(); // Call the recording function
            }
        }
        };
        // Add event listener for keydown
        window.addEventListener('keydown', handleSpacebarPress);
        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('keydown', handleSpacebarPress);
        };
    }, [startRecording], [editingTranscript]);

    useEffect(() => {
        const handleKeyCPress = (event) => {
            if (!editingTranscript &&
                !['input', 'textarea'].includes(document.activeElement.tagName.toLowerCase())) {
                if (event.key.toLowerCase() === 'c' || event.keyCode === 67) {
                    event.preventDefault(); // Prevent default behavior (if any)
                    console.log('Enter key pressed'); // Debugging to check if the key is detected
                    timeTracking("c_pressed");
                    if (!isCopied){
                        handleCopy(); // Call the copy functions
                    }
                }
            }
        };

        // Add the event listener
        window.addEventListener('keydown', handleKeyCPress);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('keydown', handleKeyCPress);
        };
    }, [handleCopy]);

    // function to delete specific annotation
    const handleCloseSuggestion = (index) => {
        // create a new parts array without the annotation
        const updatedParts = parts.filter((part) => {

        if (!part.isAnnotated || part.index !== index) {
            return true;
        }
        return false;
        });

        // update step data with the new parts array
        updateStepData(selectedStep, {
        ...getStepData(selectedStep),
        parts: updatedParts
        });
    };

    /*
        - This function parses the annotated parts of the text and prepares them for rendering
        - It returns an array of (all) parts of the text and an array of annotations (annotated parts of the text)
    */
    const parseText = (apiText) => {
        const segments = apiText.split(/(?=\n)/);

        let parts = [];
        let annotations = [];

        console.log('jonas 1', apiText);

        let segmentCounter = 0;

        segments.forEach((segment) => {
            // Check if the segment is annotated (contains a question)
            const annotationMatch = segment.match(/- \{"text": "(.*?)", "question": "(.*?)"\}/);

            if (annotationMatch) {
                const [, text, question] = annotationMatch;
                const annotationAnswer = '';
                const predefinedAnswer = '';

                parts.push({
                    text,
                    question,
                    isAnnotated: true,
                    index: segmentCounter-1,
                    annotationAnswer,
                    predefinedAnswer,
                });

                annotations.push({
                    text,
                    question,
                    index: annotations.length,
                    annotationAnswer,
                    predefinedAnswer,
                });
            } else {
                // Non-annotated text

                const updatedText = segmentCounter !== 0 && !segment.startsWith('-')
                    ? `\n\n${segment}`
                    : segment;

                parts.push({ text: updatedText, index: segmentCounter });
            }

            segmentCounter++;
        });

        console.log('jonas 2', parts)
        return { parts, annotations };
    };

    /*
        - This function is called when the user clicks on the "Transcribe" button
        - It sends the audio file to the backend
        - It sends the selected language and the selected component to the backend
        - The backend transcribes the audio and returns the unprocessed comments
        - The transcription is displayed on the left side of the UI
    */
    const transcribeAudio = (recording) => {
        console.log('transcribing...', recording.url);
        setIsTranscribing(true);
        api.transcribeAudio(recording.url, currentLanguage, selectedComponent, selectedStep, trackingUuid,orderNumber).then(r => {
            const newTranscript = {
                timestamp: recording.timestamp,
                text: r['unprocessed_comments'],
                carriedOver: false
            };
            updateStepData(selectedStep, {
                transcripts: [...transcripts, newTranscript]
            });
            timeTracking("whisper_transcript", newTranscript.text)
            setIsTranscribing(false);
        });
    };

    const cleanForDatabaseEntry = (data) => {
        return Object.fromEntries(
            Object.entries(data).map(([key, value]) => {
                const cleanedValue = value
                    .split('\n') // Split into lines
                    .filter(line => !line.trim().startsWith('- {"text')) // Remove lines starting with `- {"text`
                    .join('\n'); // Join lines back
                return [key, cleanedValue];
            })
        );
    };

    /*
        - This function is called when the user clicks on the "Process comments" button
        - It sends the unprocessed comments to the backend
        - The backend processes the comments and returns the processed comments with annotations
        - Annotations are parsed and saved in the state to display them in the UI
    */
        const adjustAsyncList = (step) => {
            console.log("selectedStep in Main:", selectedStep);
            if (!asyncList.includes(step) && step !== selectedStep) {
                setAsyncList(prevList => [...prevList, step]);
            }
            // if the selected step is in the asyncList, remove it
            if (asyncList.includes(selectedStep)) {
                setAsyncList(prevList => prevList.filter(item => item !== selectedStep));
            }
            console.log("asyncList in Main:", asyncList);
        };

        const processComments = () => {
            setIsProcessing(true);
            console.log("processingComment");

            const unprocessedComments = transcripts.map(t => `[${t.timestamp}] ${t.text}`).join('\n\n');
            timeTracking("process_comments");

            api.processComments(unprocessedComments, selectedStep, selectedComponent, trackingUuid, orderNumber).then(r => {
                Object.entries(r).forEach(([step, comment]) => {
                    if (step.length > 0) {
                        adjustAsyncList(step);
                        if (step === selectedStep) {
                            // Update processedComments for the current step (right container)
                            const parsedText = parseText(comment);
                            updateStepData(step, {
                                processedComments: comment,
                                parts: parsedText.parts,
                                annotations: parsedText.annotations
                            });
                            timeTracking("process_comments_content", comment);
                        } else {
                            // Create a new note for other steps
                            const newTranscript = {
                                timestamp: new Date().toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit'
                                }),
                                text: comment,
                                carriedOver: true
                            };
                            console.log("transcript that's supposed to go to the step '", step, "': ", newTranscript);

                            // Use the existing functions to save the new note
                            const stepData = getStepData(step);
                            updateStepData(step, {
                                ...stepData,
                                transcripts: [...(stepData.transcripts || []), newTranscript]
                            });

                            timeTracking("async", `from-${selectedStep}_to-${step}`)
                        }
                    }
                });
        
                console.log("processed comments: ", r);

                const cleanedResponse = cleanForDatabaseEntry(r);
                createDatabaseEntry(orderNumber, "steam_oven", cleanedResponse, true);

                setIsProcessing(false);
            });
        };

        useEffect(() => {
            const handlePPress = (event) => {
                console.log("EDITING: ", editingTranscript)
                if (!editingTranscript) {
                    if ((event.code === 'p' || event.keyCode === 80) && !['input', 'textarea'].includes(document.activeElement.tagName.toLowerCase())) {  
                        event.preventDefault(); // Prevent default behavior (if any)
                        console.log('P key pressed'); // Debugging to check if the key is detected
                        timeTracking("p_pressed")
                        processComments(); // Trigger the recording function
                    }
                }
            };
            // Add event listener for keydown
            window.addEventListener('keydown', handlePPress);
            // Cleanup the event listener when the component is unmounted
            return () => {
                window.removeEventListener('keydown', handlePPress);
            };
        }, [processComments], [editingTranscript]);
    
    /*
        - This function is called when the user clicks on the "Confirm answers" button
        - It sends the annotated answers to the backend in specific format
        - The backend processes the answers and returns the updated comments
    */

    const getGuidanceList = () => {
        if (showGuidancePopup) {
            setShowGuidancePopup(false);
        } else {
            api.guidanceList(selectedComponent, selectedStep).then(r => {
                const guidanceList = r && r.guidance_list && typeof r.guidance_list === 'string' 
                ? r.guidance_list 
                : '';
                setSelectedGuidance(r.guidance_list);
                console.log("Guidance List:", guidanceList);
                setShowGuidancePopup(true);
            });
            timeTracking("show_guidancelist")
        }
    };


    const handleLogout = async () => {
        try {
            // Attempt to log out via the API
            console.log("Authorization Header:", `Bearer ${localStorage.getItem('jwtToken')}`);

            const response = await api.logout();


            // Remove the token from localStorage
            localStorage.removeItem('jwtToken');
            localStorage.removeItem('username');

            // Confirm the token is deleted
            if (!localStorage.getItem('jwtToken')) {
                console.log("Token successfully deleted. Redirecting to login.");
                window.location.href = '/login'; // Redirect user after logout
            } else {
                throw new Error("Failed to delete JWT token from localStorage.");
            }
        } catch (error) {
            console.error("Logout failed:", error.message);
            alert("An error occurred while logging out. Please try again.");
        }
    };




    const handleMouseEnter = () => {
        setShowUserGuide(true);
      };

    const handleMouseLeave = () => {
        setShowUserGuide(false);
      };

    return (
        <Container>
           <Navbar
                steps={selectedMenu}
                selectedStep={selectedStep}
                onStepChange={newStep => setSelectedStep(newStep)}
                asyncList={asyncList}
                onSignOut={async () => {
                    await handleLogout();
                }}
            />;
    
            <Wrapper style={{ height: '100%' }}>
            <RepairButtonContainer>
            <CustomButton 
                text={translate("startNewRepair")}
                onClick={handleNumberButtonClick}
                style={{ 
                    height: '32px',
                    padding: '0 12px'
                }}
            />
            {showNumberInput && (
                <>
                <CustomInput
                    type="number"
                    value={tempOrderNr}
                    onChange={(e) => setTempOrderNr(e.target.value)}
                    placeholder="Enter number"
                    style={{ 
                        height: '32px',
                        padding: '0 12px'
                    }}
                    />
                    <CustomButton
                        text="Save"
                        iconName="FaSave"
                        onClick={handleOrderNumSave}
                        style={{ 
                            height: '32px',
                            padding: '0 12px'
                        }}
                    />
            </>
            )}
            {orderNumber && !showNumberInput && (
                <span style={{ 
                    fontSize: '1em',
                    fontWeight: 'bold'
                }}>
                    #{orderNumber}
                </span>
            )}
        </RepairButtonContainer>
                <MainContainer data-container>
                    {/* Left side of the container - written/transcripted notes */}
                    <LeftSide>
                        <SideHeadline>
                            {translate("yourNotes")}
                        <CustomButton text = {translate("guidanceList")} iconName="FaInfo" onClick={() => { getGuidanceList(); }} style={{ width: '22%', padding: '8px 8px', fontSize: '0.8em', backgroundColor: '#FFA500',}}/>
                        </SideHeadline>
                        <SideContentWrapper>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: 15, marginBottom: 15 }}>
                                <CustomSelect
                                    options={componentOptions}
                                    onChange={handleComponentChange}
                                    value={selectedComponent}
                                />
                                <CustomSelect style={{marginLeft: 10}}
                                    options={TRANSCRIPT_LANGUAGES}
                                    onChange={e => setCurrentLanguage(e.target.value)}
                                    value={currentLanguage}
                                />
                                <CustomButton style={{marginLeft: 10}} disabled={isTranscribing} text={isRecording ? translate("stopRecording") : translate("recordNote")} iconName={isRecording ? 'FaRegStopCircle' : 'FaMicrophone'} isRecording={isRecording} onClick={startRecording}/>
                            </div>
                            <div style={{ width: '100%', flexGrow: 1, marginBottom: 15, border: '1px solid #ced4da', borderRadius: '4px', position: 'relative', display: 'flex', flexDirection: 'column', height: 'calc(100vh - 380px)'}}>
                                <div style={{ overflowY: 'auto', padding: '10px', flexGrow: 1, marginBottom: '40px' }} onClick={handleAreaClick} >
                                    {transcripts.map((transcript, index) => (
                                        <TranscriptDisplay
                                            key={index}
                                            transcript={transcript}
                                            onEdit={() => handleEditTranscript(index)}
                                            onDelete={() => handleDeleteTranscript(index)}
                                        />
                                    ))}
                                    {(isRecording || isTranscribing) && recordingStartTime && (
                                        <VoiceMemoDisplay startTime={recordingStartTime} isRecording={isRecording} />
                                    )}
                                </div>
                                <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, padding: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                                    <CustomButton
                                        text={translate("newNote")}
                                        iconName="FaPlus"
                                        onClick={handleNewNote}
                                        style={{ width: '20%', padding: '5px 10px', fontSize: '0.8em'}}
                                    />
                                </div>
                            </div>
                            <div style={{ 
                                display: 'flex', 
                                padding: '8px',
                                position: 'absolute',
                                bottom: 0,
                                left: 16,
                                zIndex: 1
                            }}>
                                <CustomButton 
                                    onClick={goToPreviousStep} 
                                    iconName="FaArrowLeft"
                                    text={translate("previous")}
                                    disabled={selectedMenu.indexOf(selectedStep) === 0}
                                />
                         </div>
                        </SideContentWrapper>
                    </LeftSide>
                    {showUserGuide && <UserGuide />}

                    <RightSide data-right-side>
                        <SideHeadline> {translate("sapInput")} - {selectedStep}  </SideHeadline>
                        <div style={{ position: 'absolute', marginBottom: 0, left: 0, right: 0, padding: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                        </div>
                        <SideContentWrapper>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15, marginBottom: 15 }}>
                            {isProcessing && <Spinner />}

                            {/* Edit/Save, Process Comments, and Confirm Answers Buttons */}
                            <CustomButton
                                style={{ marginLeft: 'auto', marginRight: 10 }} // Pushes all buttons to the right and adds space
                                text={isEditingOutput ? translate("save") : translate("edit")}
                                iconName={isEditingOutput ? 'FaSave' : 'FaEdit'}
                                disabled={isProcessing || annotations.length > 0 || parts.length === 0}
                                onClick={handleToggleEditOutput}
                            />
                            <CustomButton
                                style={{ marginRight: 10 }} // Space between Process Comments and Edit/Save
                                disabled={isProcessing}
                                text={translate("processComments")}
                                iconName='FaSync'
                                spinningIcon={isProcessing}
                                onClick={() => { processComments(); }}
                            />
                        </div>
                            <ProcessedCommentContainer style={{ marginBottom: 15, flexGrow: 1, whiteSpace: 'pre-wrap' }}>
                                {/* The processed comments are rendered here, including their highlighted clickable parts */}
                                <ProcessedCommentWrapper>
                                    {isEditingOutput ? (
                                        <textarea
                                            value={editedParts.join('\n')} // Combine parts into a single textarea with line breaks
                                            onChange={(e) => setEditedParts(e.target.value.split(/\r?\n/))} // Split on line breaks
                                            style={{width: '95%', marginBottom: '10px', height: '300px'}}
                                        />
                                    ) : (
                                        <div style={{ whiteSpace: 'pre-wrap'}}>
                                            {parts.map((part, index) => {
                                            if (part.isAnnotated) return null; // Exclude annotations here
                                            return (
                                                <span
                                                    key={`display_${index}`}
                                                    style={{
                                                        backgroundColor: hoveredHighlight === part.index && parts.some(p => p.isAnnotated && p.index === part.index) ? '#ffff99' : 'transparent',
                                                        padding: '2px',
                                                    }}
                                                >
                                                    {part.text.trim()}<br/>
                                                </span>
                                            );
                                            })}
                                        </div>
                                    )
                                    }</ProcessedCommentWrapper>


                                <SuggestionsContainer>
                                {(() => {
                                    let annotatedPartsCount = 0;
                                    return parts.map((part, index) => {
                                    if(!part.isAnnotated) return null;
                                    const currentAnnotationIndex = annotatedPartsCount++;
                                    return (
                                        // reintroduced constraint of suggestion window size from Sascha's branch
                                        <SuggestionWindow  style={{
                                            maxWidth: '200px',
                                            width: '100%',
                                            textAlign: 'left',
                                            marginBottom: '10px',
                                            boxSizing: 'border-box',
                                        }}
                                        key={'suggestion_' + index}
                                        onMouseEnter={() => {
                                            setHoveredHighlight(part.index);
                                        }}
                                        onMouseLeave={() => {
                                            setHoveredHighlight(null);
                                        }}
                                        >
                                        <CloseButton
                                            onClick={() => {
                                                handleCloseSuggestion(part.index);
                                            }}
                                        >
                                            ×
                                        </CloseButton>
                                        <SuggestionText>{part.question}</SuggestionText>
                                        <div style={{display: 'flex', flexDirection: 'column', gap: 5, alignItems: 'center'}}>
                                        </div>
                                        </SuggestionWindow>
                                    )
                                    });
                                })()}
                                </SuggestionsContainer>
                            </ProcessedCommentContainer>
                            <div style={{ 
                                display: 'flex', 
                                justifyContent: 'space-between',
                                padding: '8px',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 4
                            }}>
                    <div style={{ position: "relative", display: "inline-block" }}>
                        <CustomButton
                            onClick={handleCopy}
                            text={isCopied ? translate("copied") : translate("copy")}
                            iconName="FaCopy"
                            style = {{marginLeft: 627}}
                        />
                        {copyPopup.visible && <CopyPopup>{copyPopup.message} </CopyPopup>}
                    </div>
                    <CustomButton 
                        onClick={goToNextStep} 
                        text={translate("next")} 
                        disabled={selectedMenu.indexOf(selectedStep) === selectedMenu.length - 1}
                        iconName="FaArrowRight"
                    />
                    </div>
                        </SideContentWrapper>
                    </RightSide>
                    {showGuidancePopup && (
                        <GuidanceListPopup 
                        guidanceList={selectedGuidance || ''}
                        onClose={() => setShowGuidancePopup(false)}
                        />
                    )}

                </MainContainer>
            </Wrapper>
                <div>
                    <CustomButton
                        text={translate("userGuide")}
                        iconName="FaInfo"
                        onMouseEnter={handleMouseEnter}  // Show UserGuide on hover
                        onMouseLeave={handleMouseLeave}  // Hide UserGuide when not hovering
                        style={{ position: 'absolute', left: '22px', bottom: '15px', backgroundColor: 'grey'}}
                        />
                </div>
                <div style={{ position: 'absolute', right: '22px', bottom: '15px'}}>
                    <DropZone  />
                </div>
                {editingTranscript && (
        <TranscriptEditor
            transcript={editingTranscript}
            onSave={(savedTranscript) => {
                handleSaveTranscript(savedTranscript);
                setEditingTranscript(null);
            }}
            onClose={() => setEditingTranscript(null)}
    />

    )}
    <OrderNumberModal
        isOpen={showOrderModal}
        onSubmit={handleOrderNumSave}
        tempOrderNr={tempOrderNr}
        setTempOrderNr={setTempOrderNr}
    />
        </Container>
    );
}

export default Main;
